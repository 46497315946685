import { useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { Trans } from 'react-i18next';
import * as yup from 'yup';
import { string } from 'yup';
import { Button } from '@/components/common/buttons/Button/Button';
import { FormWrapper } from '@/components/common/form/FormWithReCaptcha/FormWithReCaptcha';
import { FormContextProvider, useFormContext } from '@/components/common/form/context/FormContext';
import { Field } from '@/components/common/form/modules/Field/Field';
import type { IFormFields } from '@/components/common/form/types';
import {
  useDeletedAccountNotification,
} from '@/components/pages/auth/SignIn/lib/hooks/useDeletedAccountNotification/useDeletedAccountNotification';
import { useSignInContext } from '@/components/pages/auth/SignIn/model/SignInContext';
import { AuthContainer } from '@/components/pages/auth/ui/AuthContainer/AuthContainer';
import { AuthFooter } from '@/components/pages/auth/ui/AuthFooter/AuthFooter';
import { AuthLink } from '@/components/pages/auth/ui/AuthLink/AuthLink';
import { AuthPageHeader } from '@/components/pages/auth/ui/AuthPageHeader/AuthPageHeader';
import { SocialLoginButtons } from '@/components/pages/auth/widgets/SocialLoginButtons/SocialLoginButtons';
import { useTranslation } from '@/hooks/useTranslation';
import { routes } from '@/utils/routes/routes';

export const SignIn = () => {
  const { logoSrc, pageTitle, fields: { email, password } } = useSignInContext();
  const { translate } = useTranslation();
  const fields: IFormFields = useMemo(() => [
    {
      ...email,
      type: 'text',
      autoFocus: true,
      autoComplete: 'email',
      validation: string()
        .required(translate('Form.validation.required_email')),
      'data-test': 'email',
    },
    {
      ...password,
      type: 'password',
      validation: yup
        .string()
        .required(translate('Form.validation.required_password')),
      'data-test': 'password',
      groupClassName: 'mb-6!',
    },
  ], [email.name, password.name]);
  useDeletedAccountNotification();

  return (
    <AuthContainer isBackground={true}>
      <AuthPageHeader title={pageTitle} logo={logoSrc} />
      <FormContextProvider
        fields={fields}
        reactHookFormProps={{
          mode: 'onChange',
        }}
      >
        <FormInner formFields={fields} />
      </FormContextProvider>
      <SocialLoginButtons />
      <AuthFooter>
        <Trans
          i18nKey={'SignIn.no_account'}
          components={{
            signUp: <AuthLink href={routes.SIGN_UP.url()} />,
          }}
        />
      </AuthFooter>
    </AuthContainer>
  );
};

const FormInner = ({ formFields }: { formFields: IFormFields }) => {
  const { isActiveDirectoryEnabled, errorMessage, fields: { email, password } } = useSignInContext();
  const { translate } = useTranslation();
  const { setError, clearErrors, state: { isSubmitting } } = useFormContext();
  const emailFieldValue = useWatch({
    name: email.name,
  });
  const passwordFieldValue = useWatch({
    name: password.name,
  });
  const isDemo = useMemo(() => window.location.href.includes('demo'), [window.location.href]);

  useEffect(() => {
    if (errorMessage) {
      setError(email.name, {
        message: ' ',
      });
      setError(password.name, {
        message: errorMessage,
      });

      clearErrors();
    }
  }, [errorMessage, email.name, password.name, emailFieldValue, passwordFieldValue]);

  return (
    <FormWrapper
      form={{
        method: 'POST',
        action: '',
      }}
      forceSubmit={isDemo && !errorMessage && emailFieldValue && passwordFieldValue}
    >
      {formFields.map(field => <Field key={field.name} {...field} />)}
      {!isActiveDirectoryEnabled && (
        <div className={'text-right'}>
          <a href={routes.FORGOTTEN_PASSWORD.url()} className={'text-xs text-gray-900 dark:text-dark-gray-900 no-underline! focus:text-blue-600 hover:text-blue-600'}>
            {translate('SignIn.forgotPassword')}
          </a>
        </div>
      )}
      <Button
        className={'w-full mt-12'}
        data-testid='login-button'
        name={'login'}
        id={'go'}
        type={'submit'}
        loading={isSubmitting}
      >
        {translate('SignIn.form.submit_button')}
      </Button>
    </FormWrapper>
  );
};
