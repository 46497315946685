import { ErrorBoundary } from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { ReduxWrappedComponent } from '@/components/ReduxWrappedComponent';
import { SignIn } from '@/components/pages/auth/SignIn/SignIn';
import type { SignInProps } from '@/components/pages/auth/SignIn/SignIn.types';
import { SignInProvider } from '@/components/pages/auth/SignIn/model/SignInContext';
import { convertHTMLAttributesToReactProps } from '@/utils/common';

window.addEventListener('DOMContentLoaded', () => {
  const rootContainer = document.getElementById('react-sign-in-root');

  if (!rootContainer) {
    throw Error('react-sign-in-root not exist on DOMContentLoaded');
  }

  const root = createRoot(rootContainer);
  const props = convertHTMLAttributesToReactProps(rootContainer) as SignInProps;

  root.render(
    <ErrorBoundary>
      <SignInProvider value={props}>
        <ReduxWrappedComponent component={SignIn} props={props} />
      </SignInProvider>
    </ErrorBoundary>,
  );
});
