import { createContext, useContext } from 'react';
import type { SignInProps } from '@/components/pages/auth/SignIn/SignIn.types';
import type { PropsWithChildren } from 'react';

const defaultState: SignInProps = {
  pageTitle: '',
  logoSrc: '',
  fields: {
    password: {
      name: '',
      placeholder: '',
      id: '',
      defaultValue: '',
    },
    email: {
      name: '',
      placeholder: '',
      id: '',
      defaultValue: '',
    },
  },
  isActiveDirectoryEnabled: false,
  errorMessage: '',
};

const SignInContext = createContext<SignInProps>(defaultState);

export const SignInProvider = ({ children, value }: PropsWithChildren<{
  value: SignInProps
}>) => {
  const mergedProps = {
    ...defaultState,
    ...value,
  };

  return (
    <SignInContext.Provider value={mergedProps}>
      {children}
    </SignInContext.Provider>
  );
};

export const useSignInContext = () => {
  const context = useContext(SignInContext);

  if (!context) {
    throw new Error('useSignInContext must be used within an SignInContext');
  }

  return context;
};
