import { useEffect } from 'react';
import { Paragraph } from '@/components/common/Paragraph/Paragraph';
import { useNotifications } from '@/hooks/useNotifications/useNotifications';
import { useTranslation } from '@/hooks/useTranslation';

export const useDeletedAccountNotification = () => {
  const { dispatchNotification } = useNotifications();
  const { translate } = useTranslation();

  useEffect(() => {
    const IS_DELETED_ACCOUNT = 'is_deleted_account';
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.get(IS_DELETED_ACCOUNT)) {
      const onClose = () => {
        const url = new URL(window.location.href);
        url.searchParams.delete(IS_DELETED_ACCOUNT);
        window.history.pushState(null, '', url.toString());
      };

      dispatchNotification({
        variant: 'warning',
        canClose: true,
        time: 5,
        onClose,
        children: (
          <div>
            <Paragraph className={'m-0! font-semibold'}>
              {translate('DeleteAccount.confirmation_of_account_deletion.title')}
            </Paragraph>
            <Paragraph className={'m-0!'}>
              {translate('DeleteAccount.confirmation_of_account_deletion.description')}
            </Paragraph>
          </div>
        ),
      });

      return () => {
        onClose();
      };
    }
  }, [window.location.search]);
};
